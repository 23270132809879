.rc-tree-group-info .rc-tree-group-info-treenode {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
  display: flex;
  align-items: center;
  padding: 2px 8px;

  width: 100%;
  background-color: white;
  color: #8a9594;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.rc-tree-group-info .rc-tree-group-info-treenode .draggable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
}

.rc-tree-group-info .rc-tree-group-info-treenode-draggable {
  cursor: grab;
}

.rc-tree-group-info .rc-tree-group-info-treenode.drop-target {
  border-bottom: 2px solid rgba(127, 213, 209);
}

.rc-tree-group-info
  .rc-tree-group-info-treenode
  .rc-tree-group-info-node-content-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  align-items: center;
}
.rc-tree-group-info
  .rc-tree-group-info-treenode
  span.rc-tree-group-info-switcher,
.rc-tree-group-info
  .rc-tree-group-info-treenode
  span.rc-tree-group-info-checkbox,
.rc-tree-group-info
  .rc-tree-group-info-treenode
  span.rc-tree-group-info-iconEle {
  display: flex;
  align-items: center;
  margin-right: 3px;
  cursor: pointer;
}

.rc-tree-group-info-treenode-disabled > span:not(.rc-tree-group-info-switcher),
.rc-tree-group-info-treenode-disabled > a,
.rc-tree-group-info-treenode-disabled > a span {
  color: #767676;
  cursor: not-allowed;
}

.rc-tree-group-info-treenode-disabled,
.rc-tree-group-info-treenode-disabled > span {
  background-color: #c7cccc;
  cursor: not-allowed !important;
}

.rc-tree-group-info-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.rc-tree-group-info-indent {
  display: inline-block;
  height: 0;
  vertical-align: bottom;
}

.rc-tree-group-info-indent-unit {
  display: inline-block;
  width: 24px;
}
