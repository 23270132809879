.customSelectedTab.Mui-selected {
  background-color: #029f98;
  color: white !important;
}

.Wrapper .MuiFormControl-root {
  /* width: 60%; */
  width: 100% !important;
}

.WrapperSelectDropDown {
  /* max-width: 300px; */
  max-height: 450px;
}

.Wrapper .MuiFormControl-root {
  width: 49%;
}

.WrapperAddFlow {
  width: 45%;
}
.WrapperAddFlow .MuiFormControl-root {
  width: 100%;
}

.ContainerSelectOption .MuiFormControl-root {
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root.CustomTextAlign
  .MuiInputBase-root
  .MuiInputBase-input {
  text-align: center;
}

/* mobile */
@media (max-width: 767px) {
  /* .TestClass .MuiTabs-scroller .MuiTabs-flexContainer {
    flex-wrap: wrap;
  } */
  .customSelectedTab.Mui-selected {
    width: 30%;
    /* width: 100%; */
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.customSelectedTab {
    width: 30%;
  }
  .WrapperDate.MuiBox-root {
    display: block;
  }
  .DateContent {
    width: 95%;
  }
  .Wrapper .MuiFormControl-root {
    /* width: 60%; */
    width: 91.5%;
  }
  .WrapperAddFlow {
    width: 60%;
  }
  .TextInputFlowName {
    width: 86% !important;
  }
  .ContainerSelectOption.MuiBox-root {
    flex-wrap: wrap;
  }
}
/* tablet */
/* @media (max-width: 991px) and (min-width: 768px) {
  .Wrapper .MuiFormControl-root {
    width: 70%;
  }
} */
.error-message {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
  color: #de1a56;
  visibility: inherit;
}

.customMenuItem .MuiSelect-select.MuiInputBase-input {
  max-width: 160px;
}
.sizeContainer {
  max-width: 1500px !important;
}
